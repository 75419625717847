import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import ChevronLeft from '@/assets/icons/chevron-left.svg';
import Button, {
  ButtonShape,
  ButtonSize,
  ButtonVariants,
} from '@/components/switchback/Button/Button';
import ShareButton from '@/components/ui/ShareButton/ShareButton';
import ShareModal from '@/components/ui/ShareModal/ShareModal';
import { ESearchFilters } from '@/constants/searchFilters';
import { PHOTOS } from '@/constants/urls';
import { useAppRoute } from '@/context/AppRouteContext';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { useRouter } from '@/hooks/useRouter';
import { IData } from '@/services/types/search/rentals/id';
import { isPreviousPageOutdoorsy } from '@/utility/session';

import FullSaveButton from '../SaveButton/FullSaveButton';
import css from './HeaderActions.module.css';

interface ISocialProps {
  description: string;
  socialImage?: string;
  title: string;
}

interface IProps {
  isCampsite?: boolean;
  isStay?: boolean;
  listingData?: IData;
  social: ISocialProps;
  onSave: () => void;
  onShare: () => void;
  onExploreThisVehicle: () => void;
  isFavorite?: boolean;
  loading?: boolean;
  photoCount: number;
  isOutdoorsyStay?: boolean;
}

export const useFixedHeaderVisibility = (id: string) => {
  const { isMobile } = useBreakpoint();

  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState('absolute');
  const headerActionsInitialTop = useRef(0);

  useEffect(() => {
    if (!isMobile) return;

    const onScroll = () => {
      const currentScrollPos = window.scrollY;
      const headerActionsEl = document.getElementById(id);

      if (!headerActionsEl) return;

      const headerActionsOffset = headerActionsEl.getBoundingClientRect();
      if (currentScrollPos === 0) {
        setPosition('absolute');
        setIsVisible(false);
      } else {
        if (headerActionsOffset.top > 0) {
          headerActionsInitialTop.current = headerActionsOffset.top + currentScrollPos;
          if (currentScrollPos >= headerActionsInitialTop.current) {
            setPosition('fixed');
            setIsVisible(true);
          }
        } else {
          if (headerActionsInitialTop.current < currentScrollPos) {
            setPosition('fixed');
            setIsVisible(true);
          }
        }
      }
    };

    window.addEventListener('scroll', onScroll, { passive: true });

    return () => window.removeEventListener('scroll', onScroll);
  }, [id, isMobile]);

  return { isVisible, position };
};

const HeaderActions: React.FC<IProps> = ({
  isCampsite,
  isStay,
  isOutdoorsyStay,
  social,
  onSave,
  onShare,
  onExploreThisVehicle,
  listingData,
  isFavorite,
  loading,
  photoCount,
}) => {
  const intl = useIntl();
  const router = useRouter();
  const { prev: appPreviousURL } = useAppRoute();
  const { description, socialImage, title } = social;
  const [isInternal, setIsInternal] = useState(false);
  const [isShareOpen, setIsShareOpen] = useState(false);

  // Builds string of query parameters to navigate to gallery
  const params = new URLSearchParams(router.query as Record<string, string>).toString();
  const { isMobile } = useBreakpoint();
  const { isVisible: showHeaderBackground, position: headerActionsPosition } =
    useFixedHeaderVisibility('header-actions');

  useEffect(() => {
    setIsInternal(isPreviousPageOutdoorsy());
  }, []);

  const handleShare = useCallback(async () => {
    if (window?.navigator && window?.navigator.share) {
      try {
        await window.navigator.share({
          text: description,
          title: title,
          url: window.location.href,
        });
        onShare();
      } catch {
        return;
      }
    } else {
      if (isShareOpen) {
        onShare();
      }
      setIsShareOpen(!isShareOpen);
    }
  }, [description, isShareOpen, onShare, title]);

  const query = router.query;
  const urlPrefix = isCampsite ? '/campsite-rental/' : isStay ? '/stay-rental/' : '/rv-rental/';
  // We do not want to surface the campgrounds tab in production search
  // It's soft hidden behind filter[rental_category]=campground, which we don't want users getting to.
  // If you are at a campsite listing, take users back to stays tab.
  const rentalCategory = isCampsite || isStay ? 'stay' : 'rv';

  const handleBackToSearch = useCallback(() => {
    const previousURL = document.referrer;
    let url;

    if (!isInternal) {
      const near = listingData?.location
        ? [listingData.location.lat, listingData.location.lng].join(',')
        : '';
      url = {
        pathname: '/rv-search',
        query: { [ESearchFilters.NEAR]: near, [ESearchFilters.RENTAL_CATEGORY]: rentalCategory },
      };
    } else if (previousURL.includes('/rv-search') && window?.history.length > 2) {
      url = previousURL;
    } else {
      url = '/rv-search';
    }

    if (!isInternal && !isMobile) {
      router.push(url, undefined, { shallow: true });
    } else {
      router.replace(url, undefined, { shallow: true });
    }
  }, [isInternal, isMobile, listingData, rentalCategory, router]);

  return (
    <>
      {isMobile ? (
        <>
          <div
            className={`fixed top-0 left-0 z-50 w-full h-20 bg-primary-contrast transition-transform duration-200 ease-in-out border-b border-gray-200 ${
              showHeaderBackground ? 'translate-y-0' : '-translate-y-full'
            }`}
          />
          <div
            className={`${headerActionsPosition} top-0 justify-between w-full pointer-events-none p-4 flex z-50`}
            id="header-actions">
            {!appPreviousURL && (
              <Button
                variant={
                  showHeaderBackground ? ButtonVariants.borderless : ButtonVariants.whiteContained
                }
                shape={ButtonShape.circle}
                className="mr-3 pointer-events-auto"
                onClick={handleBackToSearch}>
                <ChevronLeft />
              </Button>
            )}
            <span className="flex ml-auto">
              <FullSaveButton
                onClick={onSave}
                isActive={isFavorite}
                shape={ButtonShape.circle}
                size={ButtonSize.normal}
                variant={
                  showHeaderBackground ? ButtonVariants.borderless : ButtonVariants.whiteContained
                }
              />
              <ShareButton
                onClick={handleShare}
                shape={ButtonShape.circle}
                size={ButtonSize.normal}
                variant={
                  showHeaderBackground ? ButtonVariants.borderless : ButtonVariants.whiteContained
                }
              />
            </span>
          </div>
        </>
      ) : (
        <>
          {!isInternal && (
            <div className="absolute top-0 left-0 w-full pointer-events-none grid grid-cols-main gap-x-default">
              <div className="flex flex-row mt-4 col-start-1 col-content md:mt-7 xxxl:mt-8 xxxl:col-start-0 xxxl:ml-8">
                <Button
                  variant={ButtonVariants.whiteContained}
                  shape={ButtonShape.pill}
                  className="mr-3 pointer-events-auto highlight"
                  onClick={handleBackToSearch}>
                  <ChevronLeft />
                  <span className="hidden ml-1 md:inline-block">
                    {intl.formatMessage({
                      defaultMessage: 'See all listings',
                      id: 'rzBmsU',
                    })}
                  </span>
                </Button>
              </div>
            </div>
          )}

          <div
            className={`${css.container} absolute bottom-0 left-0 w-full pointer-events-none grid grid-cols-main gap-x-default`}
            data-loading={loading}>
            <div className="flex flex-row mb-4 col-start-1 col-content md:mb-7 xxxl:mb-8 xxxl:col-start-0 xxxl:ml-8">
              <Button
                variant={ButtonVariants.whiteContained}
                size={ButtonSize.xsmall}
                href={`${urlPrefix}${query.location}/${query.slug}${PHOTOS}?${params}`}
                onClick={onExploreThisVehicle}
                className="mr-3 pointer-events-auto highlight">
                <FormattedMessage
                  defaultMessage="View {photoCount, plural, one {# photo} other {# photos}}"
                  id="7bdc8w"
                  values={{ photoCount }}
                />
              </Button>
              <FullSaveButton onClick={onSave} isActive={isFavorite} />
              <ShareButton onClick={handleShare} />
            </div>
          </div>
        </>
      )}
      <ShareModal
        title={title}
        description={description}
        socialImage={socialImage}
        listingData={listingData}
        isOpen={isShareOpen}
        onClose={handleShare}
        isOutdoorsyStay={isOutdoorsyStay}
      />
    </>
  );
};

export default HeaderActions;
