import React from 'react';
import { useIntl } from 'react-intl';

import Button, {
  ButtonShape,
  ButtonSize,
  ButtonVariants,
} from '@/components/switchback/Button/Button';
import { HEART, HEART_FILLED } from '@/components/switchback/Icon/assets';
import Icon, { IconSize } from '@/components/switchback/Icon/IconComponent';

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isActive?: boolean;
  shape?: ButtonShape;
  size?: ButtonSize;
  variant?: ButtonVariants;
}

const FullSaveButton: React.FC<IProps> = ({
  onClick,
  isActive,
  size = ButtonSize.xsmall,
  shape = ButtonShape.pill,
  variant = ButtonVariants.whiteContained,
}) => {
  const intl = useIntl();

  return (
    <Button
      variant={variant}
      size={size}
      shape={shape}
      testid={`listing-save-btn${isActive ? '-active' : ''}`}
      className="mr-3 pointer-events-auto"
      onClick={onClick}>
      <Icon name={isActive ? HEART_FILLED : HEART} size={IconSize.normal} />
      <span className="hidden ml-1 md:inline-block">
        {isActive ? (
          <>
            {intl.formatMessage({
              defaultMessage: 'Saved',
              id: 'fsB/4p',
            })}
            <span aria-live="assertive" className="sr-only">
              {intl.formatMessage({ defaultMessage: 'Saved', id: 'fsB/4p' })}
            </span>
          </>
        ) : (
          <>
            {intl.formatMessage({
              defaultMessage: 'Save',
              id: 'jvo0vs',
            })}
            <span aria-live="assertive" className="sr-only">
              {intl.formatMessage({ defaultMessage: 'Click to save', id: 'q+y0s3' })}
            </span>
          </>
        )}
      </span>
    </Button>
  );
};

export default FullSaveButton;
