import React from 'react';
import { useIntl } from 'react-intl';

import Button, {
  ButtonShape,
  ButtonSize,
  ButtonVariants,
} from '@/components/switchback/Button/Button';
import { SHARE } from '@/components/switchback/Icon/assets';
import Icon, { IconSize } from '@/components/switchback/Icon/IconComponent';

interface IShareButtonProps {
  onClick?: () => void;
  shape?: ButtonShape;
  size?: ButtonSize;
  variant?: ButtonVariants;
}
const ShareButton: React.FC<IShareButtonProps> = ({
  onClick,
  shape = ButtonShape.pill,
  size = ButtonSize.xsmall,
  variant = ButtonVariants.whiteContained,
}) => {
  const intl = useIntl();

  return (
    <Button
      data-testid="listing-share-btn"
      variant={variant}
      size={size}
      shape={shape}
      className="pointer-events-auto"
      onClick={onClick}>
      <Icon name={SHARE} size={IconSize.large} />
      <span className="hidden md:inline">
        {intl.formatMessage({
          defaultMessage: 'Share',
          id: 'OKhRC6',
        })}
      </span>
    </Button>
  );
};

export default ShareButton;
